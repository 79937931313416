import React from 'react';

import { Icon } from '@types';

const RSSIcon: Icon = ({ fill = "white" }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24" 
    height="24" 
    viewBox="0 0 24 24"
  >
    <g>
      <path
        fill={fill}
        d="M6.18,15.64A2.18,2.18 0 0,1 8.36,17.82C8.36,19 7.38,20 6.18,20C5,20 4,19 4,17.82A2.18,2.18 0 0,1 6.18,15.64M4,4.44A15.56,15.56 0 0,1 19.56,20H16.73A12.73,12.73 0 0,0 4,7.27V4.44M4,10.1A9.9,9.9 0 0,1 13.9,20H11.07A7.07,7.07 0 0,0 4,12.93V10.1Z"
      />
    </g>
  </svg>
);

export default RSSIcon;